<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="relative z-10" @close="close">
      <AppDialogsCommonBackdrop />

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex md:min-h-fit min-h-full justify-center text-center p-0"
        >
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="transform rounded-0 bg-white p-3.5 mt-0 text-left transition-all w-full relative md:mt-32 md:rounded-md md:w-[728px] md:p-5"
            >
              <AppDialogsCommonCloseButton
                class="absolute right-5 top-5 md:top-6"
                @click="close"
              />
              <div v-loading="pending" class="flex flex-col gap-5 min-h-fit">
                <h2 class="text-xl font-bold max-w-[80%]">
                  {{ t('Наявність у містах і магазинах') }}
                </h2>
                <div class="flex flex-col gap-2.5">
                  <p class="text-xs font-bold">
                    {{ t('Інтернет-магазин') }}
                  </p>
                  <div
                    class="grid sm:grid-cols-[1fr_110px_100px] sm:items-center sm:gap-10 gap-2.5 py-1.5 items-start"
                  >
                    <div class="flex text-xs max-w-[360px]">
                      {{ webStore?.chainStore?.name }}
                    </div>

                    <template v-if="webStore?.inStock">
                      <div
                        class="flex justify-start items-center text-xs text-green-ibis gap-1 sm:ml-0 shrink-0 ml-0"
                      >
                        <IconsProductAvailabilityCheck class="h-2.25 w-3.5" />
                        <span>{{ t('Є в наявності') }}</span>
                      </div>

                      <button
                        type="button"
                        class="btn btn-primary text-xs sm:!px-2.5 sm:!py-2 !px-5 !py-2.5 flex items-center gap-2.5 uppercase mr-auto"
                        @click="buyHandler"
                      >
                        <IconsButtonCart class="w-3 h-3" />
                        <span>{{ t('Купити') }}</span>
                      </button>
                    </template>
                    <template v-else>
                      <div
                        class="flex items-center text-xs gap-1 shrink-0 text-orange"
                      >
                        <IconsProductNotAvailable class="h-2.5" />
                        <span>{{ t('Немає') }}</span>
                      </div>
                      <div class="w-20 sm:ml-10 ml-6"></div>
                    </template>
                  </div>
                </div>
                <div
                  v-for="location in locations"
                  :key="location"
                  class="flex flex-col gap-2.5"
                >
                  <p class="text-xs font-bold">
                    {{ location.name }}
                  </p>
                  <template
                    v-for="warehouse in warehouses.filter(
                      w =>
                        w?.chainStore?.city?.id === location.id &&
                        w?.chainStore?.address
                    )"
                    :key="warehouse.id"
                  >
                    <div
                      class="grid sm:grid-cols-[1fr_110px_100px] sm:items-center sm:gap-10 gap-2.5 py-1.5 items-start"
                    >
                      <div class="flex text-xs max-w-[360px]">
                        {{ warehouse?.chainStore?.address }}
                      </div>

                      <template v-if="warehouse.inStock">
                        <div
                          class="flex items-center text-xs text-green-ibis gap-1 shrink-0"
                        >
                          <IconsProductAvailabilityCheck class="h-2.25 w-3.5" />
                          <span>{{ t('Є в наявності') }}</span>
                        </div>

                        <button
                          type="button"
                          class="btn btn-primary text-xs sm:!px-2.5 sm:!py-2 !px-5 !py-2.5 flex items-center gap-2.5 uppercase mr-auto"
                          @click="buyHandler"
                        >
                          <IconsButtonCart class="w-3 h-3" />
                          <span>{{ t('Купити') }}</span>
                        </button>
                      </template>
                      <template v-else>
                        <div
                          class="flex items-center text-xs gap-1 shrink-0 text-orange"
                        >
                          <IconsProductNotAvailable class="h-2.5" />
                          <span>{{ t('Немає') }}</span>
                        </div>
                        <div class="w-20 sm:ml-10 ml-6"></div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import uniqBy from 'lodash/uniqBy'
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue'
import { useCartStore } from '~/stores/cart'
const { t } = useI18n()
const cartStore = useCartStore()
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  variantCode: {
    type: String,
    default: null
  }
})

const emit = defineEmits(['update:show'])

const close = () => {
  emit('update:show', false)
}

const buyHandler = async () => {
  await cartStore.addVariantToCart(props.variantCode)
  close()
  return cartStore.openCart()
}

const { data: warehouses, pending } = useLazyAsyncData(
  `warehouses-${props.variantCode}`,
  () =>
    props.show &&
    useApi().productInventories.getAll({
      'productVariant.code': props.variantCode
    }),
  {
    lazy: true,
    server: false,
    watch: [() => props.show],
    transform: res => res['hydra:member']
  }
)

const locations = computed(() => {
  return uniqBy(
    warehouses.value
      ?.filter(({ chainStore }) => chainStore?.city)
      .map(({ chainStore }) => chainStore.city),
    'id'
  )
})

const webStore = computed(() => {
  return warehouses.value?.find(item => item.chainStore?.city === null)
})
</script>
